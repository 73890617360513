import React, { useMemo } from 'react';
import { Box, Flex, useBreakpointValue, useColorModeValue } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';

import CenteredLoader from 'clipsal-cortex-ui/src/components/CenteredLoader';

import BarcodeScannerOverlay from './common/components/BarcodeScannerOverlay';
import { SIDENAV_WIDTH, TOP_NAV_SPACING_AFFORDANCE } from './common/constants';
import { useAmplitude } from './common/hooks/use-amplitude';
import { useAppReady } from './common/hooks/use-app-ready';
import { useAppUpdate } from './common/hooks/use-app-update';
import { useStatusBar } from './common/hooks/use-status-bar';
import { BusinessDetailsModalWrapper } from './features/authentication/BusinessDetailsModalWrapper';
import BottomNav from './features/navigation/BottomNav';
import SideNav from './features/navigation/SideNav';
import { UserDetailsModalWrapper } from './features/user/UserDetailsModalWrapper';
import { selectUser } from './features/user/userSlice';
import { NAV_LINKS } from './navigation-links';

export const App = () => {
  useAmplitude();
  useAppUpdate();
  const isLoaded = useAppReady();
  const { isLoggedIn } = useSelector(selectUser);
  const isMobileViewport = useBreakpointValue(
    {
      base: true,
      xl: false,
    },
    { ssr: false }
  );
  const isSidebarVisible = !isMobileViewport && isLoggedIn;
  const backgroundColor = useColorModeValue('customBodyBackground.500', 'customBodyBackground.800');
  useStatusBar({ backgroundColor });

  const sidebarOffset = useMemo(
    () => (isSidebarVisible ? `calc(100vw - ${SIDENAV_WIDTH})` : '100vw'),
    [isSidebarVisible]
  );

  /* Exists to ensure the top area (e.g. the notch on iPhone X+) does not obfuscate content. */
  const notchOffset = useMemo(
    () => (isMobileViewport ? `calc(env(safe-area-inset-top) + ${TOP_NAV_SPACING_AFFORDANCE})` : 0),
    [isMobileViewport]
  );

  return (
    <Flex bg={backgroundColor} maxWidth="100vw" minHeight="100vh" overflowX="hidden">
      {isSidebarVisible && (
        <Box minWidth={SIDENAV_WIDTH}>
          <SideNav links={NAV_LINKS} />
        </Box>
      )}
      <Box minWidth={sidebarOffset}>
        <Box pt={notchOffset} width={'100%'} zIndex={10} top={0} bg={backgroundColor} />
        <BarcodeScannerOverlay />

        {isLoaded ? (
          <Outlet />
        ) : (
          <Box h={'100vh'}>
            <CenteredLoader />
          </Box>
        )}
      </Box>

      <BusinessDetailsModalWrapper />
      <UserDetailsModalWrapper />
      {isMobileViewport && <BottomNav links={NAV_LINKS} />}
    </Flex>
  );
};
