import React from 'react';
import { Browser } from '@capacitor/browser';
import { ChevronRightIcon, MoonIcon, SettingsIcon, SunIcon } from '@chakra-ui/icons';
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Center,
  Flex,
  Grid,
  Heading,
  IconButton,
  Image,
  Text,
  useBreakpointValue,
  useColorMode,
  useColorModeValue,
  useDisclosure,
  useTheme,
} from '@chakra-ui/react';
import { BsLifePreserver } from 'react-icons/bs';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import { ReactComponent as LogoDark } from '../../../assets/images/clipsal_logo_dark.svg';
import { ReactComponent as Logo } from '../../../assets/images/clipsal_logo.svg';
import MobileTopNav from '../../../common/components/MobileTopNav';
import { BOTTOM_NAV_HEIGHT, COMPONENT_MIN_HEIGHT } from '../../../common/constants';
import {
  AccountIconInactive,
  BusinessIcon,
  CustomInfoIcon,
  SupportIcon,
  UsersIcon,
} from '../../../styles/custom-icons';
import { SHORTHAND_ENV_TYPE } from '../user-helpers';
import { selectUser } from '../userSlice';
import SupportModal from './support/SupportModal';

const ACCOUNT_LINKS = [
  {
    title: 'Account Settings',
    route: '/account/settings',
    icon: <AccountIconInactive w={6} h={6} />,
    isAdminManaged: false,
  },
  {
    title: 'Manage Users',
    route: '/account/manage_users',
    icon: <UsersIcon w={6} h={6} />,
    isAdminManaged: true,
  },
  {
    title: 'Business Details',
    route: '/account/business_details',
    icon: <BusinessIcon w={6} h={6} />,
    isAdminManaged: true,
  },
  {
    title: 'Meter Configuration',
    route: '/account/meter_configuration',
    icon: <SettingsIcon w={6} h={6} />,
    isAdminManaged: true,
    isSuperAdminManaged: true,
  },
  {
    title: 'About',
    route: '/account/about',
    icon: <CustomInfoIcon w={6} h={6} />,
    isAdminManaged: false,
  },
];

export default function AccountHome() {
  const user = useSelector(selectUser);
  const isMobileViewport = useBreakpointValue(
    {
      base: true,
      xl: false,
    },
    { ssr: false }
  );
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const theme = useTheme();
  const { toggleColorMode } = useColorMode();
  const tenantLogo = user.tenant.logo_url;
  const { background, hoverBackground, textColor, colorModeIcon, buttonBackground, LogoIcon } = useColorModeValue(
    {
      background: 'white',
      hoverBackground: 'gray.50',
      textColor: 'dusk100.500',
      colorModeIcon: <SunIcon />,
      buttonBackground: 'gray.300',
      LogoIcon: <Logo />,
    },
    {
      background: 'gray.900',
      hoverBackground: 'gray.800',
      textColor: 'dusk100.200',
      colorModeIcon: <MoonIcon />,
      buttonBackground: 'gray.700',
      LogoIcon: <LogoDark />,
    }
  );

  return (
    <Box px={[0, 0, 10]} minHeight={COMPONENT_MIN_HEIGHT}>
      {isMobileViewport ? (
        <MobileTopNav title={'Account'} backURL={'/dashboard'} />
      ) : (
        <Button as={Link} to={'/dashboard'} mt={3}>
          Back
        </Button>
      )}
      <Center pt={4}>
        <Box w={['100%', '100%', '50%']}>
          <Flex justify={'center'} align={'center'} direction={'column'} mb={3}>
            <Center>
              {tenantLogo ? (
                <Image src={tenantLogo} maxHeight={100} maxWidth={350} objectFit={'contain'} mt={2} px={4} />
              ) : (
                <Box w={200}>{LogoIcon}</Box>
              )}
            </Center>
            <Heading data-private data-testid="account-user-tenant" size={'md'} color={textColor} my={2}>
              {user.tenant.tenant_name}
            </Heading>
            <Text data-private data-testid="account-user-name" size={'sm'} color={textColor}>
              {user.user_full_name}
            </Text>

            {user.role === 'SUPER_ADMIN' && (
              <Alert mt={1} status="info" w={'max-content'} rounded={4}>
                <AlertIcon />
                You're in {SHORTHAND_ENV_TYPE} mode
              </Alert>
            )}
          </Flex>

          {ACCOUNT_LINKS.filter((account) => {
            // disable super admin managed routes for other roles
            if (account.isSuperAdminManaged) return user.role === 'SUPER_ADMIN';
            // disable admin managed routes for other roles
            if (account.isAdminManaged) return !['STAFF', 'SYSTEM_OWNER'].includes(user.role);
            return true;
          }).map(({ title, route, icon }, index) => (
            <Grid
              data-testid={`account-settings-link-${index}`}
              className="account-link"
              key={`${title}-${route}`}
              onClick={() => navigate(route)}
              role={'button'}
              _hover={{ background: hoverBackground }}
              cursor={'pointer'}
              borderTop={index === 0 ? `1px solid ${theme.colors.dusk005[500]}` : undefined}
              borderBottom={`1px solid ${theme.colors.dusk005[500]}`}
              bg={background}
              p={6}
              gridTemplateColumns={'1fr 25px'}
            >
              <Box justifyContent={'center'}>
                <Flex align="center">
                  <Box mr={2}>{icon}</Box>
                  <Text fontWeight={'bold'}>{title}</Text>
                </Flex>
              </Box>
              <Flex align={'center'} justify={'center'}>
                <ChevronRightIcon w={25} h={25} />
              </Flex>
            </Grid>
          ))}

          <Grid
            data-testid={`account-settings-link-support`}
            className="account-link"
            onClick={onOpen}
            role={'button'}
            _hover={{ background: hoverBackground }}
            cursor={'pointer'}
            borderBottom={`1px solid ${theme.colors.dusk005[500]}`}
            bg={background}
            p={6}
            gridTemplateColumns={'1fr 25px'}
          >
            <Box justifyContent={'center'}>
              <Flex align="center">
                <Box mr={2}>
                  <SupportIcon w={6} h={6} />
                </Box>
                <Text fontWeight={'bold'}>Support</Text>
              </Flex>
            </Box>
            <Flex align={'center'} justify={'center'}>
              <ChevronRightIcon w={25} h={25} />
            </Flex>
          </Grid>

          <Grid
            data-testid={`account-settings-link-help-center`}
            className="account-link"
            onClick={async () => {
              await Browser.open({ url: 'https://clipsalsolar.zendesk.com/hc/en-us' });
            }}
            role={'button'}
            _hover={{ background: hoverBackground }}
            cursor={'pointer'}
            borderBottom={`1px solid ${theme.colors.dusk005[500]}`}
            bg={background}
            p={6}
            gridTemplateColumns={'1fr 25px'}
          >
            <Box justifyContent={'center'}>
              <Flex align="center">
                <Box mr={2}>
                  <BsLifePreserver size={23} />
                </Box>
                <Text fontWeight={'bold'}>Help Center</Text>
              </Flex>
            </Box>
            <Flex align={'center'} justify={'center'}>
              <ChevronRightIcon w={25} h={25} />
            </Flex>
          </Grid>

          {isMobileViewport && (
            <Flex direction={'column'} pb={BOTTOM_NAV_HEIGHT}>
              <Box mx={'auto'} mt={6}>
                <IconButton
                  background={buttonBackground}
                  onClick={toggleColorMode}
                  aria-label="Change color scheme"
                  icon={colorModeIcon}
                />
              </Box>
              <Button
                data-testid="account-logout-btn"
                w={'fit-content'}
                mx={'auto'}
                my={4}
                onClick={() => {
                  if (window.confirm('Are you sure you want to log out?')) navigate('/logout');
                }}
                variant={'ghost'}
                colorScheme="red"
              >
                Logout
              </Button>
            </Flex>
          )}
        </Box>
      </Center>

      {isOpen && <SupportModal isOpen={isOpen} onClose={onClose} />}
    </Box>
  );
}
