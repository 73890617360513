import { BaseQueryFn, FetchArgs } from '@reduxjs/toolkit/dist/query';
import { API, Auth } from 'aws-amplify';

import { ENV_TYPE } from '../env-type';

/**
 * Performs a GET request to a provided name/resource path on the API.
 *
 * @param path The path from the end point resource
 */
export async function get<T = any>(path: string): Promise<T> {
  // Auth.currentSession() automatically uses local session variables to self-refresh, preventing unnecessary API calls.
  const session = await Auth.currentSession();
  const token = session.getIdToken().getJwtToken();

  const headers: Record<string, string> = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };

  return await API.get('site', path, {
    headers,
  });
}

/**
 * Performs a POST request to a provided name/resource path on the API.
 *
 * @param path The path of the resource.
 * @param body The request body.
 */
export async function post<T = any>(path: string, body: object): Promise<T> {
  // Auth.currentSession() automatically uses local session variables to self-refresh, preventing unnecessary API calls.
  const session = await Auth.currentSession();
  const token = session.getIdToken().getJwtToken();

  const headers: Record<string, string> = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };

  return await API.post('site', path, {
    headers,
    body,
  });
}

/**
 * Performs a PATCH request to a provided name/resource path on the API.
 *
 * @param path The path of the resource.
 * @param body The request body.
 */
export async function patch<T = any>(path: string, body: object): Promise<T> {
  // Auth.currentSession() automatically uses local session variables to self-refresh, preventing unnecessary API calls.
  const session = await Auth.currentSession();
  const token = session.getIdToken().getJwtToken();

  const headers: Record<string, string> = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
    'x-api-key': import.meta.env[`VITE_${ENV_TYPE}_API_KEY`] as string,
  };

  return await API.patch('site', path, {
    headers,
    body,
  });
}

/**
 * Performs a DELETE request to a provided name/resource path on the API.
 *
 * @param path The path of the resource.
 * @param body The request body.
 */
export async function del<T = any>(path: string, body: object): Promise<T> {
  // Auth.currentSession() automatically uses local session variables to self-refresh, preventing unnecessary API calls.
  const session = await Auth.currentSession();
  const token = session.getIdToken().getJwtToken();

  const headers: Record<string, string> = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
    'x-api-key': import.meta.env[`VITE_${ENV_TYPE}_API_KEY`] as string,
  };

  return await API.del('site', path, {
    headers,
    body,
  });
}

/**
 * Performs a PUT request to a provided resource path on the API.
 *
 * @param path The path of the resource.
 * @param body The request body.
 */
export async function put<T = any>(path: string, body: object): Promise<T> {
  // Auth.currentSession() automatically uses local session variables to self-refresh, preventing unnecessary API calls.
  const session = await Auth.currentSession();
  const token = session.getIdToken().getJwtToken();

  const headers: Record<string, string> = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
    'x-api-key': import.meta.env[`VITE_${ENV_TYPE}_API_KEY`] as string,
  };

  return await API.put('site', path, {
    headers,
    body,
  });
}

export type ApiFunction = ((path: string, body: object) => Promise<any>) | ((path: string) => Promise<any>);
export type APIMethods = 'GET' | 'POST' | 'DELETE' | 'PATCH' | 'PUT';
export type QueryArgs = string | (FetchArgs & { method: APIMethods; site?: string });

const mapApiMethodToApiFunction: Record<APIMethods, ApiFunction> = {
  GET: get,
  POST: post,
  DELETE: del,
  PATCH: patch,
  PUT: put,
};

export type ErrorResponse = {
  detail: string;
  code: string;
  status_code: number;
};

export const customBaseQuery =
  (): BaseQueryFn<QueryArgs, unknown, { status: number; message: string }> => async (args) => {
    try {
      const url = typeof args === 'string' ? args : args.url;
      const method = typeof args === 'string' ? 'GET' : args.method;
      const body = typeof args === 'string' ? {} : args.body;
      const apiFunction = mapApiMethodToApiFunction[method || 'GET'];
      const data = await apiFunction(url, body);
      return { data };
    } catch (error: any) {
      return {
        error: {
          status: error?.status || error?.response?.status || 500,
          message: error?.message || error?.response?.data || '',
        },
      };
    }
  };
