import React from 'react';
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Input,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  UnorderedList,
  useToast,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Amplify, API } from 'aws-amplify';
import { useForm } from 'react-hook-form';
import { ImInfo } from 'react-icons/im';
import * as yup from 'yup';

import { ModalProps } from '../../common/types/types';
import { ENV_TYPE } from '../../env-type';
import { ExperimentalFeature, getFlagValue, setFlagValue } from '../../utils/experimental-feature-helpers';

const validationSchema = yup.object({
  customEndpoint: yup.string(),
});

function getInitialFormValues(): FormValues {
  return {
    [ExperimentalFeature.Placeholder]: getFlagValue(ExperimentalFeature.Placeholder),
    customEndpoint: localStorage.getItem('customEndpoint') ?? '',
  };
}

const getFormOptions = () => ({
  defaultValues: getInitialFormValues(),
  resolver: yupResolver(validationSchema),
});

export default function ExperimentalFeaturesModal({ onClose, isOpen }: ModalProps) {
  const toast = useToast();

  function handleSubmitExperimentalFeaturesForm(values: FormValues) {
    // Set custom endpoint
    localStorage.setItem('customEndpoint', values.customEndpoint);

    // Set each value in local storage when submitted
    Object.entries(values)
      .filter(([fieldName]) => fieldName !== 'customEndpoint')
      .forEach(([fieldName, value]) => {
        setFlagValue(fieldName as ExperimentalFeature, value as boolean);
      });

    if (values.customEndpoint) {
      const endpoint =
        localStorage.getItem('customEndpoint') || (import.meta.env[`VITE_${ENV_TYPE}_ENDPOINT`] as string);
      const apiConfig = {
        endpoints: [
          {
            name: 'site',
            endpoint: endpoint,
            region: 'ap-southeast-2',
          },
        ],
      };

      Amplify.configure({
        Auth: {
          region: 'ap-southeast-2',
          userPoolId: import.meta.env?.[`VITE_${ENV_TYPE}_USER_POOL_ID`],
          userPoolWebClientId: import.meta.env?.[`VITE_${ENV_TYPE}_CLIENT_ID`],
        },
        API: apiConfig,
      });

      API.configure(apiConfig);
    }

    toast({
      title: `Successfully updated experimental features`,
      status: 'success',
      duration: 2000,
      isClosable: true,
    });

    onClose();
    // Refresh to reset the application
    window.location.reload();
  }

  return (
    <>
      <Modal size={'xl'} isCentered onClose={onClose} isOpen={isOpen} motionPreset="slideInBottom">
        <ModalOverlay />
        <ModalContent p={4}>
          <ModalHeader>Experimental Features</ModalHeader>
          <ModalCloseButton />

          <ModalBody>
            <Alert mb={2} status="info">
              <AlertIcon />
              <Flex>
                <Box as={'span'}>
                  Use the options below to enable experimental features in the app. These features are{' '}
                  <Box as={'span'} fontWeight={'900'}>
                    experimental
                  </Box>
                  , so please use with knowledge that they may not be fully functional. Note that these features are
                  only available to administrators.
                </Box>
              </Flex>
            </Alert>

            <ExperimentalFeaturesForm onSubmit={handleSubmitExperimentalFeaturesForm} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

type FormValues = Record<ExperimentalFeature, boolean> & { customEndpoint: string };

interface FormProps {
  onSubmit: (values: FormValues) => void;
}

function ExperimentalFeaturesForm({ onSubmit }: FormProps) {
  const fieldLabels = {
    [ExperimentalFeature.Placeholder]: 'This is a placeholder and it does nothing.',
  };
  const { register, handleSubmit } = useForm<FormValues>(getFormOptions());

  return (
    <Box onSubmit={handleSubmit(onSubmit)} as={'form'}>
      {Object.entries(ExperimentalFeature).map(([_, enumValue], i) => (
        <FormControl key={`experimental-feature-field-${i}`}>
          <FormControl>
            <Checkbox
              data-testid={fieldLabels[enumValue].replaceAll(' ', '-').slice(0, -1).toLowerCase()}
              {...register(enumValue as any)}
            >
              {fieldLabels[enumValue]}
            </Checkbox>
          </FormControl>
        </FormControl>
      ))}

      <FormControl mt={3}>
        <FormLabel>
          <Flex alignItems="flex-start">
            <Box as="span" mr={1}>
              Custom Endpoint
            </Box>
            <Popover>
              <PopoverTrigger>
                <Box mt={1} type={'button'} as={'button'}>
                  <Box as={ImInfo} color={'customBlue.500'} size="18px" />
                </Box>
              </PopoverTrigger>
              <PopoverContent>
                <PopoverHeader fontWeight="semibold">Notes on changing endpoints</PopoverHeader>
                <PopoverArrow />
                <PopoverCloseButton />
                <PopoverBody>
                  <UnorderedList>
                    <ListItem>
                      The endpoint being called needs to use the same Cognito authorizer as the currently logged-in
                      development environment, e.g. if you are currently in the production environment, the new API
                      endpoint must authorize against the production Cognito pool
                    </ListItem>
                    <ListItem>
                      To reset to the environment currently selected at the login screen, clear this field and submit
                      this form.
                    </ListItem>
                  </UnorderedList>
                </PopoverBody>
              </PopoverContent>
            </Popover>{' '}
          </Flex>
        </FormLabel>
        <Input
          {...register('customEndpoint')}
          placeholder={'e.g. https://app-api.clipsalcortex.com'}
          id={'customEndpoint'}
        />
      </FormControl>

      <Flex mt={6}>
        <Button
          data-testid={'experimental-features-modal-save-btn'}
          type={'submit'}
          colorScheme="primaryBranding"
          ml={'auto'}
        >
          Save
        </Button>
      </Flex>
    </Box>
  );
}
