import React, { ReactElement, RefObject } from 'react';
import { Navigate } from 'react-router-dom';

import { RouteNotFound } from './common/components/route/RouteNotFound';
import Login from './features/authentication/login/Login';
import Logout from './features/authentication/Logout';
import OTPVerification, { AccountActivationInfo } from './features/authentication/signup/OTPVerification';
import Signup from './features/authentication/signup/Signup';
import SignupForm from './features/authentication/signup/SignupForm';
import Dashboard from './features/dashboard/Dashboard';
import FleetDashboard from './features/fleet-dashboard/FleetDashboard';
import CustomerDetails from './features/site/customer-details/CustomerDetails';
import BatteryTest from './features/site/meter-setup/circuit-tests/BatteryTest';
import CircuitTests from './features/site/meter-setup/circuit-tests/CircuitTests';
import GridTest from './features/site/meter-setup/circuit-tests/GridTest';
import HybridTest from './features/site/meter-setup/circuit-tests/HybridInverterTest';
import LoadAppliances from './features/site/meter-setup/circuit-tests/LoadAppliances';
import LoadTest from './features/site/meter-setup/circuit-tests/LoadTest';
import SolarTest from './features/site/meter-setup/circuit-tests/SolarTest';
import CTConfiguration from './features/site/meter-setup/ct-configuration/CTConfigurationContainer';
import MeterSetup from './features/site/meter-setup/MeterSetup';
import MeterSetupForm from './features/site/meter-setup/MeterSetupForm';
import MeterSetupPollingWrapper from './features/site/meter-setup/MeterSetupPollingWrapper';
import SwitchConfiguration from './features/site/meter-setup/switch-configuration/SwitchConfigurationContainer';
import WifiConfiguration from './features/site/meter-setup/wifi-configuration/WifiConfiguration';
import Site from './features/site/Site';
import SiteDetails from './features/site/site-details/SiteDetails';
import SystemDetails from './features/site/system-details/SystemDetails';
import BatteryUpsell from './features/upsell/BatteryUpsell';
import BatteryUpsellDashboard from './features/upsell/BatteryUpsellDashboard';
import BatteryUpsellSimulations from './features/upsell/BatteryUpsellSimulations';
import Account from './features/user/account/Account';
import AccountAbout from './features/user/account/AccountAbout';
import AccountHome from './features/user/account/AccountHome';
import AccountSettings from './features/user/account/AccountSettings';
import BusinessDetails from './features/user/account/BusinessDetails';
import ManageUsers from './features/user/account/manage-users/ManageUsers';
import MeterConfiguration from './features/user/account/meter-configuration/MeterConfiguration';

export interface Route {
  path: string;
  element: ReactElement;
  isPublic?: boolean;
  children?: Route[];
  nodeRef?: RefObject<HTMLDivElement>;
}

// eslint-disable-next-line react-refresh/only-export-components
export const routes: Route[] = [
  {
    path: '/',
    element: <Navigate to="/dashboard" />,
    isPublic: true,
  },
  {
    path: '/dashboard',
    element: <Dashboard />,
  },
  {
    path: '/login',
    element: <Login />,
    isPublic: true,
  },
  {
    path: '/signup',
    element: <Signup />,
    isPublic: true,
    children: [
      {
        path: '',
        element: <Navigate to="user-details" />,
        isPublic: true,
      },
      {
        path: 'user-details',
        element: <SignupForm />,
        isPublic: true,
      },
      {
        path: 'otp-verify',
        element: <OTPVerification />,
        isPublic: true,
      },
      {
        path: 'account-activation-info',
        element: <AccountActivationInfo />,
        isPublic: true,
      },
    ],
  },
  {
    path: '/logout',
    element: <Logout />,
    isPublic: true,
  },
  {
    path: '/site/:id',
    element: <Site />,
    children: [
      {
        path: 'start',
        element: <SiteDetails />,
      },
      {
        path: 'customer_details',
        element: <CustomerDetails />,
      },
      {
        path: 'system_details',
        element: <SystemDetails />,
      },
      {
        path: 'meter_setup',
        element: <MeterSetup />,
        children: [
          {
            path: 'meters',
            element: <MeterSetupForm />,
          },
          {
            path: 'configure',
            element: <MeterSetupPollingWrapper />,
            children: [
              {
                path: 'meter_tests',
                element: <CircuitTests />,
              },
              {
                path: 'ct_configuration',
                element: <CTConfiguration />,
              },
              {
                path: 'wifi_configuration',
                element: <WifiConfiguration />,
              },
              {
                path: 'switch_configuration',
                element: <SwitchConfiguration />,
              },
              {
                path: 'loads_list',
                element: <LoadAppliances />,
              },
              {
                path: 'loads/:appliance_id',
                element: <LoadTest />,
              },
              {
                path: 'grid',
                element: <GridTest />,
              },
              {
                path: 'solar',
                element: <SolarTest />,
              },
              {
                path: 'hybrid',
                element: <HybridTest />,
              },
              {
                path: 'battery',
                element: <BatteryTest />,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: '/account',
    element: <Account />,
    children: [
      {
        path: '',
        element: <Navigate to="home" />,
      },
      {
        path: 'home',
        element: <AccountHome />,
      },
      {
        path: 'settings',
        element: <AccountSettings />,
      },
      {
        path: 'about',
        element: <AccountAbout />,
      },
      {
        path: 'manage_users',
        element: <ManageUsers />,
      },
      {
        path: 'business_details',
        element: <BusinessDetails />,
      },
      {
        path: '/account/meter_configuration',
        element: <MeterConfiguration />,
      },
    ],
  },
  {
    path: '/fleet_dashboard',
    element: <FleetDashboard />,
  },
  {
    path: '/upsells',
    element: <BatteryUpsell />,
    children: [
      {
        path: '',
        element: <Navigate to="home" />,
      },
      {
        path: 'home',
        element: <BatteryUpsellDashboard />,
      },
      {
        path: 'site/:id/simulations',
        element: <BatteryUpsellSimulations />,
      },
    ],
  },
  {
    path: '*',
    element: <RouteNotFound />,
  },
];
