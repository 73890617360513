import React from 'react';
import { ChevronRightIcon } from '@chakra-ui/icons';
import { Button, Flex, Heading, Skeleton, Text, useColorModeValue } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

import { FleetSystemHealthStatus } from '../../../api/api-fleet-data';
import { OperatingStatus } from '../../../api/api-site-alerts';
import { SYSTEM_HEALTH_STATUS_CONFIG } from '../fleet-dashboard-helpers';

type Props = {
  systemHealthStatus: FleetSystemHealthStatus;
  isLoaded: boolean;
};

export default function SystemHealthStatusWidget({ systemHealthStatus, isLoaded }: Props) {
  const navigate = useNavigate();
  const { iconColor, iconType, shadowColor } = useColorModeValue(
    { iconColor: 'dusk100.500', iconType: 'light', shadowColor: 'rgba(43, 46, 45, 0.1)' },
    { iconColor: 'dusk100.50', iconType: 'dark', shadowColor: 'rgba(236, 236, 236, 0.1)' }
  );

  return (
    <>
      <Heading size={'md'} mb={2}>
        Operating Status
      </Heading>
      <Flex direction="column" justify="space-around" height="100%" pb={4}>
        {Object.entries(systemHealthStatus).map(([status, count], idx) => {
          const { title, icon, darkIcon, color } = SYSTEM_HEALTH_STATUS_CONFIG[status as OperatingStatus];
          const Icon = iconType === 'dark' ? darkIcon : icon;
          return (
            <Skeleton isLoaded={isLoaded} borderRadius={4} key={status + idx}>
              <Button
                variant={'ghost'}
                w="100%"
                h="100%"
                boxShadow={title === 'Offline' ? 'none' : `inset 0px -1px 0px ${shadowColor}`}
                px={0}
                borderBottomLeftRadius={0}
                borderBottomRightRadius={0}
              >
                <Flex
                  w="100%"
                  h="100%"
                  justifyContent="space-between"
                  px={4}
                  py={6}
                  onClick={() => navigate(`/dashboard?operatingStatus=${status}`)}
                >
                  <Flex align="center">
                    <Icon color={iconColor} w={8} h={8} />
                    <Text ml={4}>{title}</Text>
                  </Flex>
                  <Flex align="center">
                    <Text fontWeight="bold" color={color}>
                      {count}
                    </Text>
                    <ChevronRightIcon h={6} w={6} ml={6} color={iconColor} />
                  </Flex>
                </Flex>
              </Button>
            </Skeleton>
          );
        })}
      </Flex>
    </>
  );
}
