import * as amplitude from '@amplitude/analytics-browser';
import LogRocket from 'logrocket';
import ReactGA from 'react-ga4';
import { GaOptions } from 'react-ga4/types/ga4';
import { v4 as uuid } from 'uuid';

import packageJSON from '../../../package.json';
import { UserData } from '../../api/api-user';
import { ENV_TYPE_TO_SHORTHAND, EnvironmentType, ShorthandEnvironmentType } from '../../env-type';

export const SHORTHAND_ENV_TYPE: ShorthandEnvironmentType | null =
  ((localStorage.getItem('cortexEnvType') as ShorthandEnvironmentType | null) ||
    ENV_TYPE_TO_SHORTHAND[import.meta.env['MODE'] as EnvironmentType]) ??
  'DEV';

export const IOS_APP_STORE_REVIEW_EMAIl = 'helpdesk@clipsalsolar.com';

// urls with private data that we want to hide from logrocket
const PROTECTED_URL_REGEX = [
  /\/fleet\/invitations(.*)$/,
  /\/fleet\/sites\/[0-9]+\/users(.*)/,
  /\/fleet\/v2\/users(.*)/,
  /\/fleet\/users(.*)/,
  /\/fleet\/current_user/,
];

export function setupLogRocket(userData: UserData) {
  LogRocket.init('ks0prb/clipsal-cortex-fleet', {
    release: packageJSON.version,
    network: {
      requestSanitizer: (request) => {
        const { url } = request;
        const hasPrivateData = PROTECTED_URL_REGEX.some((urlRegex) => urlRegex.test(url));
        if (hasPrivateData) request.body = '';

        if (request.headers) {
          request.headers['authorization'] = '';
          request.headers['Authorization'] = '';
        }
        return request;
      },
      responseSanitizer: (response) => {
        // log rocket type definition does not have url but there is url in response
        const url = ('url' in response ? response?.url : '') as string;
        const hasPrivateData = PROTECTED_URL_REGEX.some((urlRegex) => urlRegex.test(url));
        return hasPrivateData ? null : response;
      },
    },
  });

  if (userData?.user_id) {
    LogRocket.identify(userData.user_id.toString(), {
      role: userData.role,
      tenantId: userData.tenant_id,
      version: packageJSON.version,
    });
  }
}

export function setupGA(userData: UserData) {
  if (userData && userData?.user_id) {
    // Update user details in GA, if we're in prod.
    const trackingId = import.meta.env[`VITE_GA_TRACKING_ID`] as string;
    let clientId = localStorage.getItem('ga:clientId');

    if (!clientId) {
      // Generate random id for client identifier, put into local storage (should only run on first open).
      clientId = uuid();
      localStorage.setItem('ga:clientId', clientId);
    }

    const options: GaOptions = {
      userId: userData.user_id.toString(),
      cookieDomain: 'none',
      clientId: clientId ?? undefined,
    };

    ReactGA.initialize(trackingId, {
      gaOptions: options,
    });

    ReactGA.set({
      checkProtocolTask: null,
      user_properties: {
        role: userData.role,
        tenant_id: userData.tenant_id,
        app_version: packageJSON.version,
        user_id_dimension: userData.user_id.toString(),
      },
    });

    ReactGA.send({ hitType: 'pageview', page: window.location.pathname + window.location.search });
  }
}

export async function setupAmplitude(userData: UserData) {
  await amplitude.init(import.meta.env.VITE_AMPLITUDE_API_KEY, userData?.user_id?.toString(), {
    appVersion: packageJSON.version,
    defaultTracking: {
      // Default page views will send an event on initial page load, but won't update event properties to the values
      // we do on route change (i.e. updating path from '/site/123/dashboard' to '/site/<id>/dashboard`.
      // Because of this, the default page view mechanism is disabled, and we manually trigger these events through
      // react router (see App.tsx).
      pageViews: false,
      sessions: true,
      formInteractions: true,
      fileDownloads: true,
    },
  }).promise;

  // Identify the current user with some extra properties
  const identifyEvent = new amplitude.Identify();
  identifyEvent.set('Role', userData.role);
  identifyEvent.set('Tenant Id', userData.tenant_id);
  identifyEvent.set('App Version', packageJSON.version);
  amplitude.identify(identifyEvent);

  // Initial page view event -- if we got here, the user is on some sort of page
  // replace the site ID with a placeholder
  const pathname = window.location.pathname.replace(/^\/site\/\d+\//, '/site/<id>/');

  // Required event properties for page views
  const eventProperties: Record<string, string> = {
    '[Amplitude] Page Path': pathname,
  };

  if (window.location) {
    const { hostname, href, origin, host, pathname } = window.location;
    eventProperties['[Amplitude] Page Domain'] = hostname;
    eventProperties['[Amplitude] Page Location'] = href;
    eventProperties['[Amplitude] Page URL'] = href;
    eventProperties['Previous Path'] = 'FIRST_APP_VISIT';
    eventProperties['Seconds Spent on Previous Path'] = 'FIRST_APP_VISIT';
    eventProperties['LogRocket Session URL'] = LogRocket.sessionURL ?? 'NO_SESSION_AVAILABLE';
    eventProperties['referrer'] = origin + pathname;
    eventProperties['referring_domain'] = host;
  }
  // Log the first page view event.
  amplitude.logEvent('[Amplitude] Page Viewed', eventProperties);
}
